import { setCookie , getCookie } from '@/utils/utils';
const state = {
  token: null,
  url: null
};
const actions = {
  getToken(state) {
    return state.token;
  }
}
// 同步修改state
const mutations = {
  login(state, token) {
    // const days = this.$cookies.get("remember_auth") ? 56 : 1;
    const days = 56;
    const path = "/";
    setCookie("auth_token", token, {
      maxAge: 60 * 60 * 24 * days,
      path: path
    });

    state.token = token;
  },

  logout(state) {
    state.token = null;
    const path = "/";
    setCookie("auth_token", '', {
      maxAge: 0,
      path: path
    });
  },

  setToken(state, token) {
    state.token = token;
  },
  setUrl(state, url) {
    state.url = url;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};