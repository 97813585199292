import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index';
import store from './store';
import 'vant/lib/index.css';
import './plugins/vant';
// import VConsole from 'vconsole';
// new VConsole();

/* eslint-disable no-new */

import './permission'

Vue.config.productionTip = false;
Vue.prototype.env = process.env;
console.log('process=====',process.env.VUE_APP_API_URL);

Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
    el: '#app',
    router,
    components: {
        App
    },
    template: '<App/>',
    store,
    render: h => h(App)
})