<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { detectDeviceType, isWeChat } from '@/utils/utils';
import store from '@/store';
export default {
  name: "App",
  components: {},
  data() {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  beforeCreate() {
    store.commit('auth/setUrl', window.location.href.split('#')[0]);
    // 判断是不是手机微信端；是手机微信端
    var fullPath = this.$route.fullPath
    if (!detectDeviceType() || (detectDeviceType() && !isWeChat())) {
      // 判断是不是微信环境，微信环境使用新版，非微信环境使用旧版
      // PC端使用旧版
      window.opener = null;
      window.open("about:black","_top").close()
      window.location.href = `https://${process.env.VUE_APP_PC_HOSTNAME}` + fullPath
    }
  },
  created() {
  },
  methods: {
    // 获取手机机型
    isIOS(){
      var u = navigator.userAgent;
      // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 
      if(isiOS) return true
    }
  },
};
</script>

<style>
</style>
