<template>
  <div class="relative flex min-h-screen w-screen bg-gray-100 justify-center w-full sl-bg">
    <UserProfileView :profile-handle="profileHandle" />
  </div>
</template>
<script>
import UserProfileView from "@/components/profile/UserProfileView.vue";
import { getHeadline } from '../../server/api';
export default {
  name: "UShowProfilePreview",
  components: {
    UserProfileView,
  },
  watch: {
    '$route'(to,form) {
      if(to.path != form.path){
        this.profileHandle = to.path.replace('/u/', '')
      }
    },
  },
  data() {
    return {
      profileHandle: this.$route.path.replace('/u/', ''),
    };
  },
  async mounted() {
    try {
      const { code , data } = await getHeadline({
        handle :  this.$route.path.replace('/u/', ''),
        // token: this.$store.getters['auth/getToken'] || this.$cookies.get('auth_token')
      });
      if(code == 200){
        document.title = data.headline + ' - ME.ink';
      }
    } catch (error) {
      
    }
  }
};
</script>
<style>
@import "../../components/profile/index.css";
</style>