import request from '@/utils/request';

console.log(process.env);
var VUE_APP_API_URL = process.env.VUE_APP_API_URL;
// if(process.env.NODE_ENV == 'development'){
//   var VUE_APP_API_URL = process.env.VUE_APP_API_URL;
// }else {
//   var VUE_APP_API_URL = '';
// }



// 查看token是否过期
export function IsExpire(params) {
  return request({
    method: 'POST',
    url: VUE_APP_API_URL + '/user/info',
    data: params
  })
}
// 获取主页名称
export function getHeadline(params) {
  return request({
    method: 'POST',
    url: VUE_APP_API_URL + '/profile/headline',
    data: params
  })
}
// 获取主页信息
export function getProfileInfo(url,params) {
  return request({
    method: 'POST',
    url: VUE_APP_API_URL + url,
    data: params
  })
}
// 获取预览页信息
export function getPreview(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/profile/preview',
    data : params
  })
}
// 关注
export function followInfo(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/follow',
    data : params
  })
}
// 取消关注
export function cancleFollow(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/follow/cancel',
    data : params
  })
}
// 收藏
export function collectionInfo(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/collection',
    data : params
  })
}
// 取消收藏
export function cancelCollection(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/collection/cancel',
    data : params
  })
}
// 快转
export function quickTurn(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/link/quick_create',
    data : params
  })
}

// 分享
export function getShareInfo(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/share',
    data : params
  })
}

// 新建链接
export function addLinkInfo(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/link/create',
    data : params
  })
}
// 删除链接
export function delLinkInfo(params) {
  return request({
    method : 'POST',
    url: VUE_APP_API_URL + '/link/delete',
    data : params
  })
}

// 修改链接
export function updateLinkInfo(params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + '/link/update',
    data : params
  })
}

// 上传图片
export function uploadImg(url,params) {
  return request({
    method : 'POST',
    url : VUE_APP_API_URL + url,
    data : params
  })
}

// 获取签名
export function getSignature(params) {
  return request({
    method: 'POST',
    url : VUE_APP_API_URL + '/Mini/signature',
    data : params
  })
}
// 获取headle
export function getHeadlineInfo(params) {
  return request({
    method: 'POST',
    url: VUE_APP_API_URL + '/profile/active-profile',
    data: params
  })
}