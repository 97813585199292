<template>
  <div>
    <div class="flex-center home-nav">
      <img src="../../assets/index/logo-img2x.png">
      <div class="flex-center account-box" v-if="token">
        <p class="exit" @click="logout">退出</p>
      </div>
      <div v-else class="flex-center account-box">
        <a @click="goLogin">
          <p class="exit">登录/注册</p>
        </a>
      </div>
    </div>
    <div class="home-page-h5 md:hidden flex">
      <div class="home">
        <div class="warp-1">
          <div class="content1">
            <div class="cont">
              <img class="phone" src="../../assets/index/phone1x.png" />
              <div class="title-wrap">
                <div class="title1">链接你的所有想象</div>
                <img class="meink" src="../../assets/index/meink1x.png" />
                <div class="sub">一个升华个人风采的独特空间</div>
                <div class="sub">收藏所有的心动与热爱</div>
                <div class="btn-wrap">
                  <a @click="goLogin" v-if="!token">
                    <div class="btn">免费注册</div>
                  </a>
                  <a @click="goHome" v-else>
                    <div class="btn">进入ME.ink</div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <img class="bg" src="../../assets/index/logo1x-1.jpg" alt="" />
        </div>
        <div class="warp-2">
          <div class="content2">
            <div class="h1">分享众多媒体内容</div>
            <div class="h2">ME.ink空间=多平台+多账号+多内容</div>
            <div class="cont">
              <div class="title-wrap">
                <div class="btn">快速发布</div>
                <div class="sub">
                  社交媒体内容链接，快速复制到<br />自己的ME.ink空间，便于朋友、<br />粉丝等即时浏览
                </div>
                <div class="btn">集中分享</div>
                <div class="sub">
                  统一管理你的众多社交平台账号，<br />集中编辑、发布、分享，高效快捷
                </div>
              </div>
              <img class="img-1" src="../../assets/index/img1x-2.png" />
            </div>
          </div>
          <img class="bg" src="../../assets/index/logo1x-2.jpg" alt="" />
        </div>
        <div class="warp-3">
          <div class="content3">
            <div class="h1">聚合流量 增加曝光</div>
            <div class="h2">易用 快速 高效</div>
            <div class="cont">
              <img class="img-2" src="../../assets/index/img1x-3.png" />
              <div class="title-wrap">
                <div class="btn">极速链接</div>
                <div class="sub">
                  在ME.ink发布分享内容后，会形成唯一链接及二维码，方便批量转发分享、汇集增粉
                </div>
                <div class="btn">聚合运营</div>
                <div class="sub">
                  聚合多平台、多账号统一管理，实现多社交平台同步增加曝光量和粉丝的高效运营
                </div>
              </div>
            </div>
          </div>
          <img class="bg" src="../../assets/index/logo1x-3.jpg" alt="" />
        </div>
        <div class="warp-4">
          <div class="content4">
            <div class="h1">空间自定义设置</div>
            <div class="h2">随心排布 便捷编辑</div>
            <div class="cont">
              <div class="title-wrap">
                <div class="btn">内容随心选择</div>
                <div class="sub">
                  选择文章、短视频、直播动态、小说影视、食谱音乐、网络课程等多元内容，营造个性ME.ink空间
                </div>
                <div class="btn">空间自由排布</div>
                <div class="sub">突出重点内容带来众多关注，便于即时更新</div>
              </div>
              <img class="img-4" src="../../assets/index/img1x-4.png" />
            </div>
          </div>
          <img class="bg" src="../../assets/index/logo1x-4.jpg" alt="" />
        </div>
      </div>
    </div>
    <section class="footer text-center text-gray-600 mt-auto mb-4 p-8">
      ©2022 僖蜜一刻 版权所有 <a class="beian-a text-gray-600" href="https://beian.miit.gov.cn/">苏ICP备20019613号-2</a>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import { getCookie } from '@/utils/utils';
import { getHeadlineInfo } from '../../server/api';
import { Toast } from 'vant';

export default Vue.extend({
  name: "Index",
  data: () => {
    return {
      token:getCookie('auth_token'),
      handle: null
    };
  },
  created() {
    if (this.token) {
      this.getProfile(this.token)
    }
  },
  methods: {
    logout(){
      this.$store.commit('auth/login', null);
      this.token = null;
    },
    goLogin(){
      this.$router.push('/login');
    },
    // 获取headle
    async getProfile(token) {
      try {
        const { code , data } = await getHeadlineInfo({
          token: token
        })
        if (code == 200) {
          this.handle = data.handle
        } else {
          Toast.err('获取用户信息失败，请重试');
        }
      } catch (err) {
        console.log('Error getting profile');
        console.log('失败',err);
      }
    },
    goHome() {
      this.$router.push({
        path: `/u/${this.handle}`
      })
    }
  },
});
</script>

<style scoped>
/* * {
  outline: none !important;
} */
.home-nav {
    width: 100%;
    height: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 3;
}
.flex-center {
  display: flex;
  align-items: center;
}
.home-nav .account-box p {
  font-size: 12px;
  font-family: PingFangSC-Regular,PingFang SC;
  cursor: pointer;
}
.home-nav .exit{
  color: #f15a24;
}

img, video {
  max-width: 100%;
  height: auto;
  display: block;
}
.home-nav img {
  width: 45px;
}
.home-page-h5 .home {
  margin-top: 60px;
}
.home-page-h5 .warp-1 , .home-page-h5 .warp-2 , .home-page-h5 .warp-3 , .home-page-h5 .warp-4 {
  position: relative;
  width: 100vw;
  min-height: 180px;
}
.home-page-h5 .content1 ,.home-page-h5 .content2 ,.home-page-h5 .content3 ,.home-page-h5 .content4{
  position: absolute;
  width: 100vw;
  min-height: 180px;
}
.home-page-h5 .content1 .logo {
  width: 9%;
  margin-left: 5%;
}
.home-page-h5 .content1 .phone {
  width: 47%;
  min-width: 47%;
  margin-left: 8%;
  margin-top: 40px;
}
.home-page-h5 .content1 .title-wrap {
  position: absolute;
  top: 24%;
  left: 49%;
  text-align: center;
  min-height: 180px;
}
.home-page-h5 .btn-wrap .btn{
  background: #EF5D42;
  width: 80px;
  height: 30px;
  border-radius: 20px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  line-height: 30px;
  color: #FFFFFF;
  margin: auto;
  margin-top: 10px;
}
.home-page-h5 .content1 .title-wrap .title1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
}
.home-page-h5 .content1 .sub {
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 20px;
}
.home-page-h5 .content1 .meink {
  margin: auto;
  width: 34px;
  margin-bottom: 10px;
}
.home-page-h5 .content2 .h1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
  margin-left: 5%;
}
.home-page-h5 .content2 .h2 {
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 20px;
  margin-left: 5%;
}

.home-page-h5 .content2 .cont {
  position: relative;
}
.home-page-h5 .content2 .title-wrap {
  margin-left: 5%;
}
.home-page-h5 .content2 .title-wrap .title1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
}
.home-page-h5 .content2 .logo {
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}
.home-page-h5 .content2 .sub {
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 16px;
}
.home-page-h5 .content2 .btn {
  width: 60px;
  height: 22px;
  border-radius: 30px;
  background: #A33AF1;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  text-align: center;
  margin: 2px 0px;
}
.home-page-h5 .img-1 {
  position: absolute;
  left: 52%;
  width: 45%;
  top: 10px;
}
.home-page-h5 .content3 .h1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
  text-align: center;
}
.home-page-h5 .content3 .h2 {
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 20px;
  text-align: center;
}
.home-page-h5 .content3 .cont {
  position: relative;
}
.home-page-h5 .img-2 {
  width: 42%;
  position: absolute;
  left: 7%;
}
.home-page-h5 .content3 .title-wrap {
  margin-left: 50%;
}
.home-page-h5 .content3 .title-wrap .title1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
}
.home-page-h5 .content3 .logo {
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}
.home-page-h5 .content3 .sub {
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 16px;
}
.home-page-h5 .content3 .btn {
  width: 60px;
  height: 22px;
  border-radius: 30px;
  background: #A33AF1;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  text-align: center;
  margin: 2px 0px;
}


.home-page-h5 .content4 .h1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
  margin-left: 5%;
  margin-top: 10px;
}
.home-page-h5 .content4 .h2 {
  font-size: 14px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 20px;
  margin-left: 5%;
}

.home-page-h5 .content4 .cont {
  position: relative;
}
.home-page-h5 .content4 .title-wrap {
  margin-left: 5%;
  max-width: 50%;
}
.home-page-h5 .content4 .title-wrap .title1 {
  font-size: 18px;
  font-family: Alibaba PuHuiTi;
  font-weight: bold;
  color: #5B5B5B;
}
.home-page-h5 .content4 .logo {
  margin: auto;
  margin-top: 5px;
  margin-bottom: 15px;
}
.home-page-h5 .content4 .sub {
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #5B5B5B;
  line-height: 16px;
}
.home-page-h5 .content4 .btn {
  width: 85px;
  height: 22px;
  border-radius: 30px;
  background: #A33AF1;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  text-align: center;
  margin: 2px 0px;
}
.home-page-h5 .content4 .cont {
  position: relative;
}
.home-page-h5 .img-4 {
  width: 45%;
  position: absolute;
  left: 55%;
  top: 0px;
}

/* 超过 1440*/
@media (min-width: 1000px) {
  .home-page .content1 .logo {
    margin-top: 57px;
  }
  .home-page .content2 .cont {
    margin-top: 80px;
  }
  .content3 .h1 {
    /* margin-top: 60px; */
  }
  .home-page .content2 .cont {
    margin-top: 20px;
  }
  .home-page .content3 .title-wrap {
    padding-top: 140px;
  }
  .home-page .content4 .title-wrap {
    padding-top: 70px;
  }
  .home-page .sub {
    font-size: 23px;
  }
}
@media (min-width: 750px) and (max-width:1440px) {
  .sub {
    font-size: 18px !important;
  }
}
.footer {
  font-size: 12px;
  text-align: center;
}
.beian-a {
  font-size: 12px;
  margin-left: 4px;
}
</style>
