<template>
  <div>
    <van-popup v-model="show" :style="{ width: '90%' }">
      <div class="max-w-sm">
        <div class="header">
          <span>{{title}}</span>
          <van-icon name="cross" @click="close" />
        </div>
        <div class="modal">
          <div class="flex flex-col mb-3">
            <label class="font-medium text-sm text-gray-800" for="label">编辑链接</label>
            <input
              id="label"
              v-model="pendingLink.label"
              class="
                p-2
                mt-2
                text-sm
                border-solid border-gray-300
                rounded-lg
                border
              "
              type="text"
              placeholder="举例：我的官网链接"
            />
          </div>

          <div class="flex flex-col mb-3">
            <label class="font-medium text-sm text-gray-800" for="subtitle">链接介绍（选填）</label>
            <input
              id="subtitle"
              v-model="pendingLink.subtitle"
              class="
                p-2
                mt-2
                text-sm
                border-solid border-gray-300
                rounded-lg
                border
              "
              type="text"
              placeholder="举例：官网更新最新通知"
            />
          </div>

          <div class="flex flex-col mb-3">
            <label class="font-medium text-sm text-gray-800" for="link">链接地址</label>
            <input
              id="link"
              v-model="pendingLink.url"
              :disabled="type=='1'?true:false"
              class="
                p-2
                mt-2
                text-sm
                border-solid border-gray-300
                rounded-lg
                border
              "
              type="text"
              placeholder="举例：https://www.baidu.com"
            />
          </div>
          <div class="flex flex-col mb-3">
            <label class="font-medium text-sm text-gray-800" for="link"
              >上传图片（选填）</label
            >
            <van-uploader
              v-model="fileList"
              :after-read="afterRead"
              :max-count="count"
            />
          </div>
          <div class="btn-wrap">
            <van-button type="danger" size="small" @click="deleteLink" style="margin-right:20px">删除链接</van-button>
            <van-button type="info" size="small" @click="saveLinkChanges">保存修改</van-button>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from 'vant';
import { getCookie } from '@/utils/utils';
import { delLinkInfo , updateLinkInfo , uploadImg } from '../../../server/api';
import store from '../../../store';
export default {
  props : [ "show" , "pendingLink" , "title" , 'type' , "closePopup" , "getProfile"],
  data() {
    return {
      action: "/Upload/image",
      count: 1,
      fileList: [],
      // token:this.$cookies.get('auth_token'),
      token: getCookie('auth_token')
    };
  },
  created(){
    if(this.pendingLink.image){
      this.fileList = [{ url : this.pendingLink.image}]
    }else{
      this.fileList = []
    }
  },
  methods: {
    close() {
      this.closePopup();
    },
    async afterRead(file) {
      // 此时可以自行将文件上传至服务器
      const formData = new FormData();
      if (file instanceof Array) {
        //判断是否是数组
        file.map((element) => {
          element.status = "uploading";
          element.message = "上传中...";
          formData.append("file", element.file);
        });
      } else {
        file.status = "uploading";
        file.message = "上传中...";
        formData.append("file", file.file);
      }
      const { code , data } = await uploadImg(this.action,formData);
      if(code == 200){
        file.status = 'success';
        file.message = '';
        file.url = data.url;
      }
    },
    async deleteLink() {
      try {
        const { code , message, msg } = await delLinkInfo({
          token: store.state.auth.token || this.token,
          id: this.pendingLink.id,
        })
        if(code == 200){
          Toast.success('删除成功');
          this.closePopup();
        }else{
          Toast.fail(message || msg);
        }
      } catch (err) {
        console.log('Link destruction unsuccessful');
        console.log(err);
      }
    },
    async saveLinkChanges() {
      try {
        const { code , message, msg } = await updateLinkInfo({
          token: store.state.auth.token || this.token,
          link: {
            id: this.pendingLink.id,
            label: this.pendingLink.label,
            subtitle: this.pendingLink.subtitle,
            url: this.pendingLink.url,
            customCss: this.pendingLink.customCss,
            useDeepLink: this.pendingLink.useDeepLink,
            image: this.fileList.length ? this.fileList[0].url : ""
          }
        })
        if(code == 200){
          Toast.success('保存修改成功');
          this.closePopup();
        }else{
          Toast.fail(message || msg)
        }
      } catch (err) {
        console.log('Link changes unsuccessful');
        console.log(err);
      }
    },
  },
};
</script>
<style>
@import "../index.css";
@import "./index.css";

.modal {
  padding: 10px;
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 6px 10px;
  border-bottom: 1px solid #ccc;
}
.header title {
  font-size: 18px;
}
.btn-wrap {
  display: flex;
  justify-content: center;
}
</style>