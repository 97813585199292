import router from "./router";
import store from "./store";
import initWxJsSDK from "@/utils/wxJsSdk";

let signLink;

router.beforeEach((to, from, next) => {
  // const { token } = to.query;
  // if (token) {
  //   store.commit("location/SET_TOKEN", token);
  // }
  next();
});

router.afterEach((to, from, next) => {
  if(isIOS()){
    // 记录进入app时的url
    if (typeof window.entryUrl === 'undefined' || window.entryUrl === '') {
      window.entryUrl = location.href.split('#')[0]
    }
    // 将http://028h5.cn/?i=xxxxxxxxxx 转为http://028h5.cn/index?i=xxxxxxxxxx 
    if( window.entryUrl.indexOf('/?') != -1 ){
      window.entryUrl = window.entryUrl.split('/?')[0]+'/?'+window.entryUrl.split('/?')[1]
    }
    // 进行签名的时候  Android 不用使用之前的链接， ios 需要
    signLink =  /(Android)/i.test(navigator.userAgent) ? location.href.split('#')[0] : window.entryUrl;
  }else{
    signLink = window.location.href
  }
  console.log('signLink',signLink);
  if(to.name=='msg'){
    var marketerRole = store.state.location.marketerRole;
    if(marketerRole){
      initWxJsSDK({ url: signLink, appType: "market-table" });
    }else{
      initWxJsSDK({ url: signLink, appType: "shuttle-table" });
    }
  }
})

function isIOS(){
  var u = navigator.userAgent;
  // var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 
  if(isiOS) return true
}
