import Vue from "vue";
import Vuex from "vuex";
import auth from './modules/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth
  },
  //   strict: true, // 严格模式，只能通过 mutation 来更改状态
});