
import Vue from 'vue'
import { Dialog , Toast , Icon , ShareSheet , Overlay , Tab, Tabs , List , Popover , Popup, Image as VanImage  , Button , Uploader , Checkbox , CheckboxGroup , Field } from 'vant'
Vue.use(Dialog)
Vue.use(Toast)
Vue.use(Icon)
Vue.use(ShareSheet)
Vue.use(Overlay)
Vue.use(VanImage);
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(List)
Vue.use(Popover);
Vue.use(Popup);
Vue.use(Button);
Vue.use(Uploader);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Field);


