import axios from 'axios';
import router from '@/router/index';

// 请求拦截器
// axios.interceptors.request.use(conf => {
//   const token = store.state.location.token;
//   conf.headers = {
//     Authorization: `Bearer ${token}`
//   }
//   return conf;
// })
// 返回数据拦截
axios.interceptors.response.use(
  async response => {
      const data = response.data
      if(data.code == 400){
        router.push('/login?url=' + window.location.href);
      }else{
        return data
      }
    },
    function (error) {
      var newError = String(error);
      // 对响应错误做点什么
      return Promise.reject(error)
    }
)

export default axios