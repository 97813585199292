import request from '@/utils/request';


var VUE_APP_API_URL = process.env.VUE_APP_API_URL;
// if(process.env.NODE_ENV == 'development'){
//   var VUE_APP_API_URL = process.env.VUE_APP_API_URL;
// }else {
//   var VUE_APP_API_URL = '';
// }

// 获取主页名称
export function login(params) {
  return request({
    method: 'POST',
    url: VUE_APP_API_URL + '/Api/WxWeb/is_login',
    data: params
  })
}